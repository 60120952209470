<template>
	<div>
		<v-sheet class="dense-inputs">
			<v-row no-gutters>
				<v-col cols="5" lg="5" md="12" xs="12" class="px-1 pb-0 pt-3">
					<v-row no-gutters>
						<v-col cols="5" lg="5" md="3" xs="3">
							<v-select
								:items="searchFields"
								:placeholder="$t('message.searchColumn')"
								autocomplete="off"
								clearable
								prepend-icon="search"
								dense
								hide-details
								solo
								v-model="searchField"
                                @click:clear="resetSearch"
							>
							</v-select>
						</v-col>
						<v-col cols="4" lg="4" md="3" xs="3" class="pl-2">
							<v-text-field
								:placeholder="$t('message.searchTerm')"
								autocomplete="off"
								class="force-text-left"
								clearable
								dense
								hide-details
								solo
								v-model="searchTerm"
								@keyup.enter="searchIpas"
                                @click:clear="resetSearch"
							>
							</v-text-field>
						</v-col>
						<v-col cols="3" lg="3" md="3" xs="3" class="pl-2">
							<v-row no-gutters>
								<v-btn
									:loading="loading.search"
									class="col-6"
									@click="searchIpas"
								>{{ $t('message.search') }}</v-btn>
								<v-btn
									:loading="loading.reset"
									class="col-6"
									@click="resetSearch"
								>{{ $t('message.reset') }}</v-btn>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-sheet>
		<v-overlay
			:value="loading.ipas"
			absolute
			opacity="0.15"
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						color="primary"
						indeterminate
						size="40"
						width="6"
					></v-progress-circular>
				</v-col>
			</v-row>
		</v-overlay>
		<v-data-table
			:footer-props="{
				itemsPerPageOptions: [10,25,50,-1],
				showFirstLastPage: true
			}"
			:headers="headers"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
			:items="Ipas"
			:options.sync="tableOptions"
			:server-items-length="totalIpas"
			calculate-widths
			class="mt-3 appic-table-light specification-table"
			dense
			id="importPermitAdviceTable"
			item-key="Ipa.id"
		>
<!--			<template v-slot:header="{ props: { headers } }">-->
<!--				<tr>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[0].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[1].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[2].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[3].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[4].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[5].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[6].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[7].text }}</th>-->
<!--					<th class="grey lighten-3 pa-1 width-1-pct">{{ headers[8].text }}</th>-->
<!--				</tr>-->
<!--			</template>-->
			<template v-slot:item.Ipa.Contract.title="{ item }">
				<span class="text-no-wrap font-weight-bold">{{ item.Ipa.Contract.version != null ? [item.Ipa.Contract.title, item.Ipa.Contract.revision_no , item.Ipa.Contract.partial_no].filter(Boolean).join('-') : item.Ipa.Contract.title }}</span>
			</template>
            <template v-slot:item.Ipa.date="{ item }">
                {{ item.Ipa.date != '0000-00-00' ? formatDate(item.Ipa.date) : '' }}
            </template>
            <template v-slot:item.Ipa.ipasent_date="{ item }">
                {{ item.Ipa.ipasent_date != '0000-00-00' ? formatDate(item.Ipa.ipasent_date) : '' }}
            </template>
			<template v-slot:item.Ipa.Customer.title="{ item }">
				<div class="text-no-wrap">{{ item.Ipa.Customer.otsname != '' ? item.Ipa.Customer.otsname : item.Ipa.Customer.title }}</div>
			</template>
			<template v-slot:item.Ipa.Contract.etddate="{ item }">
				{{ item.Ipa.Contract.etddate != '0000-00-00' ? formatDate(item.Ipa.Contract.etddate) : '' }}
			</template>
			<template v-slot:item.Ipa.Contract.etadate="{ item }">
				{{ item.Ipa.Contract.etadate != '0000-00-00' ? formatDate(item.Ipa.Contract.etadate) : '' }}
			</template>
			<template v-slot:item.Ipa.Contract.shipmentstatus_id="{ item }">
                <span class="text-no-wrap" v-if="item.Ipa.Contract.shipmentstatus_id == 6">
                    <v-icon x-small color="grey" class="mr-1">fas fa-ship</v-icon><span :class="item.Ipa.Contract.shipmentstatus_text && item.Ipa.Contract.shipmentstatus_text.length > 10 ? 'font-xs' : ''">{{ getShipmentStatus(item.Ipa.Contract.shipmentstatus_id, item.Ipa.Contract.shipmentstatus_text) }}</span>
                </span>
			</template>
			<template v-slot:item.Ipa.id="{ item }">
				<v-menu>
					<template v-slot:activator="{ on: menu }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
									<v-icon>more_vert</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('message.moreActions') }}</span>
						</v-tooltip>
					</template>
					<v-list dense>
						<v-list-item class="font-sm" @click="updateIpa(item.Ipa.id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateIpa') }}</v-list-item>
						<v-list-item class="font-sm" @click="cancelIpa(item.Ipa.id, (item.Ipa.Contract.version != null ? [item.Ipa.Contract.title, item.Ipa.Contract.revision_no , item.Ipa.Contract.partial_no].filter(Boolean).join('-') : item.Ipa.Contract.title))"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelIpa') }}</v-list-item>
						<v-list-item class="font-sm" @click="viewPdf(item.Ipa.id)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>
					</v-list>
				</v-menu>
			</template>
		</v-data-table>
		<SimpleAlert
			:alert_message.sync="dialogs.error_message"
			:dialog.sync="dialogs.error"
			@dialog-closed="dialogClosed"
		></SimpleAlert>
	</div>
</template>

<script>
	import {formatDate} from "Helpers/helpers";
	// import SimpleAlert from "./SimpleAlert";
	import {numberFormat} from "../../helpers/helpers";
	import {mapGetters, mapActions} from "vuex";
	import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';

    const SimpleAlert = () => import("./SimpleAlert");

	export default {
		name: "IpasListing",
		components: {SimpleAlert},
		data() {
			return {
				dialogs: {
					error:  false,
					error_message: ""
				},
				filterOptions: {
					status: {
						Status: {
							id: 1,
							title: 'open'
						}
					}
				},
                hideDefaultHeader: false,
                hideDefaultFooter: false,
				loading: {
					filter: {
						buyers: false
					},
					search: false,
					ipas: false
				},
				searchField: 'Ipa.Contract.title', //default search field
				searchTerm: null,
				tableOptions: {},
			}
		},
		computed: {
			...mapMultiRowFields('ipa',{
				Ipas: 'current.Ipas'
			}),
			...mapFields('ipa',{
				totalIpas: 'current.totalIpas'
			}),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
			...mapGetters([
				'shipmentStatuses'
			]),
			formatDate: () => formatDate,
			headers () {
				let headers = [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'Ipa.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.ipaForContract'),
                        value: 'Ipa.Contract.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.ipaDate'),
                        value: 'Ipa.date',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false
                    },
					{
						id: 3,
						text: this.$t('message.consignor'),
						value: 'Ipa.Supplier.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: false
					},
                    {
                        id: 4,
                        text: this.$t('message.dateSent'),
                        value: 'Ipa.ipasent_date',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false
                    },
					{
						id: 5,
						text: this.$t('message.consignee'),
						value: 'Ipa.Customer.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: false
					},
					{
						id: 6,
						text: this.$t('message.agent'),
						value: 'Ipa.Agent.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: false
					},
					{
						id: 7,
						text: this.$t('message.etd'),
						value: 'Ipa.Contract.etddate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
						id: 8,
						text: this.$t('message.eta'),
						value: 'Ipa.Contract.etadate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
						id: 9,
						text: this.$t('message.voyage'),
						value: 'Ipa.Contract.shipmentstatus_id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
						id: 10,
						text: this.$t('message.entryPoint'),
						value: 'Ipa.Entryport.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					}
				]
				return headers
			},
			searchFields() {
				return this.headers.filter((header)=>header.searchable === true)
			},
		},
		methods: {
			...mapActions('ipa',{
				cancelIpaById: 'cancelIpaById',
				getAllIpas: 'getAllIpas',
				searchAllIpas: 'searchAllIpas'
			}),
			async cancelIpa (val, title) {
				if(await this.$root.$confirm(this.$t('message.cancelIpa') + ' ' + title, this.$t('message.confirmations.continueIpaCancelAction'), {color: 'orange'})){
					this.cancelIpaById(val).then((status) => {
						if(status == 'done'){
							this.$toast.success(this.$t('message.successes.ipaDeleted'),
								{
                                    classes: ['icon-float-left'],
									icon: 'check_circle_outline'
								}
							)
							this.loadIpas()
						} else {
							this.$toast.error(this.$t('message.errors.ipaNotDeleted'),
								{
                                    classes: ['icon-float-left'],
									icon: 'error_outline'
								}
							)
						}
					})
					.catch(()=>{
						this.$toast.error(this.$t('message.errors.ipaNotDeleted'),
							{
                                classes: ['icon-float-left'],
								icon: 'error_outline'
							}
						)
					})
					.finally(()=>{

					})
				}
			},
			dialogClosed () {
				this.dialogs.error = false;
				this.dialogs.error_message = ''
			},
			formatThisNumber(value,format){
				return numberFormat(value,format)
			},
			getShipmentStatus (id, text) {
				if(id) {
					let status = this.shipmentStatuses.find(d => d.Shipmentstatus.id == id)
					if(status?.Shipmentstatus?.hastext == 1){
						if(id == 1){
							if(parseInt(text) == text){
								return status?.Shipmentstatus?.title + ': ' + text
							} else {
								return text
							}
						} else {
							return text
						}
					} else {
						return status?.Shipmentstatus?.title
					}
				}
				return null
			},
			loadIpas () {
				this.loading.ipas = true;
				let payload = {
					tableOptions: this.tableOptions,
					filterOptions: this.filterOptions
				}
				this.getAllIpas(payload)
					.then(()=>{
						this.loading.ipas = false;
					})
					.catch(()=>{
						this.loading.ipas = false;
					})
					.finally(()=>{
						this.loading.ipas = false;
					})
			},
			resetSearch () {
				this.hideDefaultFooter = false
				this.loading.ipas = false
				this.searchField = 'Ipa.Contract.title'; //default search field
				this.searchTerm = null;
				this.tableOptions.itemsPerPage = 10
				this.filterOptions = {};
			},
			searchIpas () {
				if(this.searchField == null || this.searchTerm == null){
					this.dialogs.error = true
					this.dialogs.error_message = this.$t('message.errors.noSearchColumnAndSearchTerm')
				} else {
					//TODO search ipas
					this.filterOptions = {}
					this.hideDefaultFooter = true
					this.loading.ipas = true;
					this.loading.search = true;
					let payload = {
						tableOptions: this.tableOptions,
						search: {
							field: this.searchField,
							value: this.searchTerm
						}
					}
					this.searchAllIpas(payload)
						.then(() => {
							this.loading.search = false;
							this.loading.ipas = false;
						})
						.catch(()=>{
							this.loading.search = false;
							this.loading.ipas = false;
						})
						.finally(()=>{
							this.loading.search = false;
							this.loading.ipas = false;
						})
				}
			},
			updateIpa (val) {
                let tab = window.open('/v1/ipas/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
				// router.push({name: 'update_ipa', params: { ipaId : val}}).catch(err => {})
			},
			viewPdf (ipaId) {
                let ipa = this.Ipas.find( i => i.Ipa.id == ipaId)
                let document = encodeURIComponent('IPA ' + (ipa.Ipa.Contract.version != null ? [ipa.Ipa.Contract.title, ipa.Ipa.Contract.revision_no , ipa.Ipa.Contract.partial_no].filter(Boolean).join('-') : ipa.Ipa.Contract.title))
				let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
					process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
					+ process.env.VUE_APP_BASE_URL
					+ '/v1/ipas/print/'
					+ ipaId
					+ '/'
					+ uuidv4()
					+ '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
					, "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
			},
		},
		watch: {
			filterOptions: {
				handler(){
					if(!this.loading.search) {
						this.loadIpas()
					}
				},
				deep: true
			},
			tableOptions: {
				handler(){
					this.loadIpas()
				},
				deep: true
			},
		},
		created(){
			let dt = new Date()
			let defaultYear = dt.getFullYear() - 1
			this.loadIpas()
		}
	}
</script>

<style>
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>